<template>
    <div>
        <p class=" mb-6">Facturas de compras detallado por producto</p>

        <v-card>
            <v-card-subtitle>Filtros</v-card-subtitle>
            <v-card-text>
                <v-row>

                    <v-col cols="4">
                        <dos-fechas
                            :fechas="[filtros.fecha.desde,filtros.fecha.hasta]"
                            @getFechas="getFechas" :icon="false"></dos-fechas>
                    </v-col>
                    <v-col cols="4">
                        <AreaSelect :clearable="true"  v-model="filtros.idArea"  :dense="true" :outlined="true"></AreaSelect>
                    </v-col>

                    <v-col cols="4">
                        <v-text-field hide-details="auto" v-model="filtros.numeroDocumento"  label="NumeroDocumento" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field hide-details="auto" v-model="filtros.barra"  label="Barra Producto" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field hide-details="auto" v-model="filtros.nombreProducto"  label="Nombre Producto" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field hide-details="auto" v-model="filtros.numeroLote"  label="Numero Lote" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" class="text-right">
                        <v-btn :loading="cargando" :disabled="cargando || !validar()" color="primary"
                            @click="buscarDatos()">
                            Buscar
                        </v-btn>
                    </v-col>
                </v-row>

            </v-card-text>
            <v-divider></v-divider>
            <v-card-subtitle>Resultado</v-card-subtitle>
            <v-card-text>
                <tabla-reporte @GetVer="getVer" :acciones="['VER']" :nombreReporte="nombreReporte" :cabeceraIn="cabecera"
                    :datosIn="datos" :exportar="['EXCEL', 'PDF']"></tabla-reporte>

            </v-card-text>
        </v-card>
    </div>
</template>
    
<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import TablaReporte from '@/components/TablaReporte.vue';
import { ref } from '@vue/composition-api';
import CompraServices from '@/api/servicios/CompraServices';
import store from '@/store';
import AreaSelect from '../../archivo/area/componentes/AreaSelect.vue';
import DosFechas from '@/components/DosFechas.vue';
import FuncionesGenerales from '@/funciones/funciones'
import moment from 'moment';
// demos

export default {
    components: { TablaReporte, DosFechas, AreaSelect },
    setup() {
        const filtros = ref({

            fecha: {
                desde: moment().format('YYYY-MM-DD'),
                hasta: moment().format('YYYY-MM-DD')
            },
            Id: null,
            numeroDocumento: '',
            idArea: null,
            barra: '',
            nombreProducto: '',
            numeroLote: '',
            fechaFacturaDesde: null,
            fechaFacturaHasta: null
        })


        const nombreReporte = ref(`Reporte compra detalle por producto ${filtros.value.fecha.desde} ${filtros.value.fecha.hasta}`)
        const cabecera = ref([
            { text: 'N° Documento', value: 'compra.numeroDocumento', width: '160px', visible: true },
            { text: 'Barra', value: 'productoSucursal.producto.barra', format: { small: true }, visible: true },
            { text: 'Producto', value: 'productoSucursal.producto.nombre', width: '200px', visible: true },
            { text: 'Lote', value: 'lote.numeroLote', width: '130px', visible: true },
            { text: 'Area', value: 'area.nombre', width: '130px', visible: true },
            { text: 'Costo', value: 'monto', width: '130px', align: 'right', visible: true },
            { text: 'Cantidad', value: 'cantidad', width: '130px', align: 'right', visible: true },
            { text: 'IVA', value: 'montoIVA', width: '130px', align: 'right', visible: true },
            { text: 'Total', value: 'total', width: '130px', align: 'right', visible: true },

            { text: 'Estatus', value: 'compra.estatus.descripcion', align: 'center', format: { size: 'small', chip: true, color: ['primary', 'error'], valores: ['Finalizada', 'Anulada'] } },
            { text: 'N° Factura', value: 'compra.numeroFactura', width: '130px' },
            { text: 'N° Control', value: 'compra.numeroControl', width: '130px' },
            { text: 'Fecha Creacion', value: 'compra.fechaCreacion', format: { dateTime: true }, width: '230px' },
            { text: 'Fecha Fectura', value: 'compra.fechaFactura', format: { date: true }, width: '140px' },
        ])
        const datos = ref(
            [
            ]
        )
        const cargando = ref(false)



        const validar = () => {
            let validado = true

            return validado
        }

        const buscarDatos = () => {
            cargando.value = true
            CompraServices.ConsultarCompraDetalleReporte(
                {
                    ...
                    filtros.value,
                    fechaFacturaDesde: FuncionesGenerales.formatoFecha(filtros.value.fecha.desde, 5),
                    fechaFacturaHasta: FuncionesGenerales.formatoFecha(filtros.value.fecha.hasta, 5)
                })
                .then(response => {
                    if (response.data.estatus = true) {
                        datos.value = response.data.datos
                    } else {
                        store.commit('setAlert', {
                            message: response.data.mensaje,
                            type: 'error',
                        })
                    }

                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    cargando.value = false

                })
        }

        const getVer = (dato) => {
            console.log(dato)
        }

        const getFechas = (datos) => {
            console.log(datos)
            filtros.value.fecha.desde = datos.desde
            filtros.value.fecha.hasta = datos.hasta
        }


        return {
            cabecera,
            datos,
            validar,
            buscarDatos,
            cargando,
            nombreReporte,
            getVer,
            filtros,
            FuncionesGenerales,
            getFechas
        }
    },
}
</script>
    